import React, { useState, useEffect } from "react";
import { Container, LoadingOverlay, Badge } from "@mantine/core";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiStat,
  EuiIcon,
  EuiLoadingLogo,
  EuiEmptyPrompt,
  EuiButtonIcon,
  EuiButton,
  EuiText,
  EuiLoadingSpinner,
  EuiHorizontalRule,
} from "@elastic/eui";
import {
  completedJobsCount,
  failedJobsCount,
  jobsCount,
  typesList,
  ticketsList,
  fetching,
  activeCustomer,
  jobsList,
} from "../store";
import { useRecoilValue, useRecoilState } from "recoil";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

export default function Home() {
  const customer = useRecoilValue(activeCustomer);
  const jobCount = useRecoilValue(jobsCount);
  const completedJobs = useRecoilValue(completedJobsCount);
  const failedJobs = useRecoilValue(failedJobsCount);
  const [jobs, setJobs] = useRecoilState(jobsList);
  const jobTypes = useRecoilValue(typesList);
  const isFetching = useRecoilValue(fetching);
  const [tickets, setTickets] = useRecoilState(ticketsList);
  const [isLoading, setLoading] = useState({});
  const [isReloading, setReloading] = useState({});
  const [error, setError] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    tickets.forEach((jobType) => {
      setLoading({ ...isLoading, [jobType.name]: false });
      setReloading({ ...isReloading, [jobType.name]: false });
    });

    const refreshJobs = async () => {
      if (customer) {
        const jobsResult = await loadJobs(customer);
        setJobs(jobsResult);
      }
    };
    refreshJobs();
  }, []);

  // Auto refresh logic (1min)
  const MINUTE_MS = 60000;
  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("[AP-TE] Checking for new tickets");
      for (const jobType of jobTypes) {
        await reload(jobType.name);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  function loadJobs(customer) {
    return API.get("api", `/jobs?subdomain=${customer.subdomain}`);
  }

  async function startExport(tickets, jobType, event) {
    setExporting(true);
    let exportTickets = tickets.find((item) => item.name === jobType).tickets;
    setLoading({ ...isLoading, [jobType]: true });
    try {
      await API.post("api", "/sfn/export", {
        body: {
          tickets: exportTickets,
          type: jobType,
          customer,
        },
      });
      await wait(5000);
      history.push("/jobs");
    } catch (e) {
      setError(e);
    }
  }

  function loadTickets(jobType, customer) {
    return API.get(
      "api",
      `/tickets?tags=awaiting_export_${jobType}&customer=${customer.subdomain}`
    );
  }

  async function reload(jobType) {
    setReloading({ ...isReloading, [jobType]: true });
    try {
      let result = await loadTickets(jobType, customer); // new tickets
      let newTicketsArr = tickets.map((x) => {
        if (x.name !== jobType) return x;
        return { ...x, tickets: result };
      });
      setTickets(newTicketsArr);
    } catch (e) {
      console.log(e);
    }
    setReloading({ ...isReloading, [jobType]: false });
  }

  async function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  return (
    <>
      <Container
        style={{ height: "97vh", position: "relative", paddingTop: 70 }}
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel hasBorder style={{ height: 80 }}>
              <EuiStat
                title={jobCount}
                titleSize="s"
                description="Total Jobs"
                textAlign="right"
                isLoading={isFetching}
              >
                <EuiIcon type="empty" />
              </EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel hasBorder>
              <EuiStat
                title={completedJobs}
                titleSize="s"
                description="Completed Jobs"
                titleColor="success"
                textAlign="right"
                isLoading={isFetching}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel hasBorder>
              <EuiStat
                title={failedJobs}
                titleSize="s"
                description="Failed Jobs"
                titleColor="danger"
                textAlign="right"
                isLoading={isFetching}
              ></EuiStat>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        {isFetching ? (
          <EuiEmptyPrompt
            style={{ marginTop: 90 }}
            icon={<EuiLoadingLogo logo="dot" size="xl" />}
            title={<h2>Checking for Tickets</h2>}
            body={<p>Please wait while the data loads</p>}
          />
        ) : (
          <EuiFlexGroup direction="column" style={{ marginTop: 10 }}>
            <EuiFlexItem>
              <EuiText>
                <h3>Available Export Jobs</h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder>
                {tickets &&
                  jobTypes.map((jobType, index) => {
                    let jobTicketsCount = tickets.find(
                      (item) => item.name === jobType.name
                    ).tickets.length;
                    return (
                      <EuiFlexItem key={index}>
                        <EuiPanel
                          hasShadow={false}
                          style={{ position: "relative" }}
                        >
                          <LoadingOverlay
                            visible={isReloading[jobType.name]}
                            loader={
                              <EuiFlexGroup alignItems="center">
                                <EuiFlexItem grow={false}>
                                  <EuiLoadingSpinner size="m" />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <EuiText>
                                    <span style={{ fontWeight: "400" }}>
                                      Looking for new {jobType.name} tickets
                                    </span>
                                  </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            }
                          />
                          <EuiFlexGroup justifyContent="spaceBetween">
                            <EuiFlexItem>
                              <EuiFlexGroup alignItems="center">
                                <EuiFlexItem grow={false}>
                                  <EuiButtonIcon
                                    iconType="refresh"
                                    size="s"
                                    isDisabled={isReloading[jobType.name]}
                                    onClick={() => reload(jobType.name)}
                                    aria-label="Refresh"
                                  />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <EuiText>
                                    <h5
                                      style={{
                                        textTransform: "capitalize",
                                        width: 60,
                                      }}
                                    >
                                      {jobType.name}
                                    </h5>
                                  </EuiText>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <Badge
                                    size="sm"
                                    radius="sm"
                                    variant="outline"
                                    color={
                                      jobTicketsCount > 0 ? "green" : "gray"
                                    }
                                  >
                                    {jobTicketsCount}{" "}
                                    {jobTicketsCount !== 1
                                      ? "tickets"
                                      : "ticket"}
                                  </Badge>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiFlexGroup>
                                <EuiFlexItem>
                                  <EuiButton
                                    size="s"
                                    isLoading={isLoading[jobType.name]}
                                    isDisabled={
                                      jobTicketsCount === 0 || isExporting
                                    }
                                    onClick={() =>
                                      startExport(tickets, jobType.name)
                                    }
                                  >
                                    Export
                                  </EuiButton>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiPanel>
                        {jobType !== jobTypes[jobTypes.length - 1] && (
                          <EuiHorizontalRule margin="xs" />
                        )}
                      </EuiFlexItem>
                    );
                  })}
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </Container>
    </>
  );
}
