import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { RecoilRoot } from "recoil";
import App from "./App";
import config from "./config";
import "./index.css";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <RecoilRoot>
    <NotificationsProvider>
      <ModalsProvider>
        <Router>
          <App />
        </Router>
      </ModalsProvider>
    </NotificationsProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
