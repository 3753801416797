import React, { useState, useEffect } from "react";
import {
  EuiBasicTable,
  EuiBadge,
  EuiCode,
  EuiPanel,
  EuiEmptyPrompt,
  EuiLoadingLogo,
  EuiDescriptionList,
  EuiButtonIcon,
  RIGHT_ALIGNMENT,
  EuiLoadingSpinner,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import { usePagination, useWindowFocus } from "../libs/hooksLib";
import { Container } from "@mantine/core";
import { activeCustomer } from "../store";
import { useRecoilState, useRecoilValue } from "recoil"
import { isAuthorised } from "../store";
import { API } from "aws-amplify";
import moment from "moment";

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setAuthorised] = useRecoilState(isAuthorised)
  const [isRefreshing, setIsRefreshing] = useState(false);
  const customer = useRecoilValue(activeCustomer);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [jobs, setJobs] = useState(false);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const pageOfJobs = usePagination(jobs, 8);
  const windowFocused = useWindowFocus();

  useEffect(() => {
    async function onLoad() {
      try {
        await loadJobs();
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    }

    if (isAuthed) {
      onLoad();
    } else {
      setIsLoading(false);
      console.log("not authed");
    }
  }, [isAuthed]);

  useEffect(() => {
    let interval;
    if (windowFocused) {
      interval = setInterval(() => {
        loadJobs();
      }, 7000);
    }
    if (!windowFocused) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [windowFocused]);

  const loadJobs = async () => {
    const response = await API.get("api", `/jobs?subdomain=${customer.subdomain}`);
    console.log(response)
    response.sort((a, b) =>
      a.issuedAt < b.issuedAt ? 1 : b.issuedAt < a.issuedAt ? -1 : 0
    );
    setJobs(response);
  };

  const reload = async () => {
    try {
      setIsRefreshing(true);
      await loadJobs();
    } catch (e) {
      console.log(e);
    }
    setIsRefreshing(false);
  };

  const downloadJob = async (jobFileKey) => {
    const data = await API.get("api", `/jobs/download?jobFileKey=${jobFileKey}`);
    const { url } = data;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const deleteJob = async (id) => {
    setIsDeleting(true);
    try {
      const data = await API.del("api", `/jobs/${id}`);
      await loadJobs();
      setIsDeleting(false);
    } catch (e) {
      console.log(e);
    }
  };

  const revertJob = async (id, tickets) => {
    setIsDeleting(true);
    console.log(tickets);
    try {
      const data = await API.post("api", `/jobs/${id}`, {
        body: {
          tickets,
        },
      });
      console.log(data);
      await loadJobs();
      setIsDeleting(false);
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      field: "jobId",
      name: "Job ID",
      render: (id) => <EuiCode>{id}</EuiCode>,
    },
    {
      field: "jobType",
      name: "Job Type",
      render: (type) => <span style={{textTransform: "capitalize"}}>{type}</span>
    },
    {
      name: "# of Tickets",
      align: "center",
      render: (item) => <span>{item.tickets.length}</span>,
    },
    {
      field: "issuedAt",
      name: "Created",
      render: (uTime) => {
        let time = moment(uTime).format('DD/MM/YY');
        return <span>{time}</span>;
      },
    },
    {
      field: "status",
      name: "Status",
      render: (status) => (
        <EuiBadge
          style={{ textTransform: "uppercase" }}
          color={
            status === "Complete"
              ? "success"
              : status === "new"
              ? "accent"
              : status === "reverted"
              ? "warning"
              : status === "Failed"
              ? "danger"
              : "default"
          }
          iconType={
            status === "Complete"
              ? "check"
              : status === "new"
              ? "plus"
              : status === "reverted"
              ? "editorUndo"
              : status === "Failed"
              ? "alert"
              : null
          }
        >
          {status === "Working" && (
            <EuiLoadingSpinner
              size="s"
              style={{ position: "relative", top: "2px" }}
            />
          )}{" "}
          {status}
        </EuiBadge>
      ),
    },
    {
      name: "Actions",
      actions: [
        {
          name: <span>Download</span>,
          icon: "download",
          description: "Download .csv file",
          isPrimary: true,
          type: "icon",
          available: ({ status }) =>
            status !== "Failed" && status !== "working",
          onClick: (item) => downloadJob(item.downloadLink),
        },
        // {
        //   name: <span>Revert</span>,
        //   icon: "editorUndo",
        //   type: "icon",
        //   available: ({ status }) =>
        //     status !== "failed" && status !== "in progress",
        //   onClick: (item) => revertJob(item.jobId, item.tickets),
        // },
        {
          name: "Delete",
          icon: "trash",
          type: "icon",
          available: ({ status }) =>
          status !== "working",
          onClick: (item) => deleteJob(item.jobId),
        },
      ],
    },
    {
      align: RIGHT_ALIGNMENT,
      width: "40px",
      isExpander: true,
      
      available: ({ status }) =>
      status !== "Failed" && status !== "working",
      render: (item) => (
        <EuiButtonIcon
          disabled={item.status === "working" || item.status === "Failed"}
          onClick={() => toggleDetails(item)}
          aria-label={
            itemIdToExpandedRowMap[item.jobId] ? "Collapse" : "Expand"
          }
          iconType={
            itemIdToExpandedRowMap[item.jobId] ? "arrowUp" : "arrowDown"
          }
        />
      ),
    },
  ];

  const toggleDetails = (item) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
    if (itemIdToExpandedRowMapValues[item.jobId]) {
      delete itemIdToExpandedRowMapValues[item.jobId];
    } else {
      const { tickets, issuedAt, completedAt, lastUpdated, status } = item;
      let completed = moment(completedAt);
      let started = moment(issuedAt);
      let updated = moment(lastUpdated);
      let duration = moment.duration(completed.diff(started));
      let secs = duration.asSeconds();
      const listItemsTopLeft = [
        {
          title:
            status === "Failed"
              ? "Failed"
              : status === "reverted"
              ? "Reverted"
              : "Completed",
          description:
            status === "Failed" ? (
              <span>{moment(updated).fromNow()}</span>
            ) : status === "reverted" ? (
              <span>{moment(updated).fromNow()}</span>
            ) : (
              <span>
                {moment(completedAt).format('DD/MM/YY')} (Completed in {secs}s)
              </span>
            ),
        },
      ];
      const listItemsBottom = [
        {
          title:
            status === "Failed"
              ? "Tickets failed to update"
              : status === "reverted"
              ? "Tickets reverted"
              : "Tickets exported",
          description: <>{tickets.slice(0, 10).map((ticket) => (
            <EuiBadge key={ticket}>{ticket}</EuiBadge>
          ))}{tickets.length > 10 && <EuiBadge>and {tickets.length - 10} others</EuiBadge>}</>,
        },
      ];
      itemIdToExpandedRowMapValues[item.jobId] = (
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiDescriptionList listItems={listItemsTopLeft} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiDescriptionList listItems={listItemsBottom} />
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const pagination = {
    pageIndex,
    pageSize: 8,
    totalItemCount: jobs.length,
    hidePerPageOptions: true,
  };

  const handleTableChange = (e) => {
    setPageIndex(e.page.index);
    pageOfJobs.jump(e.page.index + 1);
  };

  return (
      <Container
        style={{ height: "100vh", position: "relative", paddingTop: 70 }}
      >
        {isLoading ? (
          <EuiEmptyPrompt
            style={{ display: "flex" }}
            icon={<EuiLoadingLogo logo="editorOrderedList" size="xl" />}
            title={<h2>Fetching job list</h2>}
          />
        ) : !isAuthed ? (
          <h3>Error: Not Authorised</h3>
        ) : (
          <EuiPanel hasBorder={true}>
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem>
                <EuiText>
                  <h3>Jobs</h3>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  iconType="refresh"
                  isLoading={isRefreshing}
                  onClick={() => reload()}
                >
                  Refresh
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiBasicTable
              tableCaption="Demo for EuiBasicTable with pagination"
              items={pageOfJobs.currentData()}
              itemId="jobId"
              tableLayout="auto"
              hasActions={true}
              pagination={pagination}
              noItemsMessage="No jobs found"
              itemIdToExpandedRowMap={itemIdToExpandedRowMap}
              isExpandable={true}
              columns={columns}
              onChange={handleTableChange}
            />
          </EuiPanel>
        )}
      </Container>
  );
}
