import {
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderSectionItem,
  EuiIcon,
  EuiProvider,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiCallOut
} from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import { LoadingOverlay } from "@mantine/core";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import ZAFClient from "zendesk_app_framework_sdk";
import "./App.css";
import Routes from "./Routes";
import {
  activeCustomer,
  fetching,
  isAuthorised,
  jobsList,
  ticketsList,
  typesList
} from "./store/index";


function App() {
  const [jobs, setJobs] = useRecoilState(jobsList);
  const setTypes = useSetRecoilState(typesList);
  const [isFetching, setFetching] = useRecoilState(fetching);
  const setTickets = useSetRecoilState(ticketsList);
  const setCustomer = useSetRecoilState(activeCustomer);
  const setAuthorised = useSetRecoilState(isAuthorised);
  const [loadStatus, setLoadStatus] = useState("Loading: Zendesk metadata")
  const [loadValue, setLoadValue] = useState(0)
  const zafClient = ZAFClient.init();

  try {
  useEffect(() => {
    const onLoad = async () => {
      console.time("Loaded APTE");
      setFetching(true);
      if (!zafClient) {
        throw new Error("App not loaded inside of a Zendesk instance.")
      }
      const metadata = await zafClient.metadata();
      try {
        const authorised = await checkAuth(metadata);
        setLoadStatus("Checking Authorisation")
        setLoadValue(5)
        if (!authorised || !zafClient) {
          // No authorisation pass OR zafClient not found (content trying to be loaded from outside Zendesk)
          setAuthorised(false);
        } else {
          setLoadValue(25)
          // Authorised
          setAuthorised(true);
          setLoadStatus("Loading: Fetching Job List")
          // Fetching the list of jobs (failed, succeeded, reverted) for this customer
          setLoadValue(50)
          const jobsResult = await loadJobs(metadata);
          setJobs(jobsResult);
          setLoadStatus("Loading: Fetching Job Types")
          // Fetching all of the possible job types for this customer
          const typesResult = await loadTypes(metadata);
          setTypes(typesResult);
          let updateArr = [];
          setLoadValue(80)
          let lVal = 80;
          for (const jobType of typesResult) {
            lVal = lVal + 2
            setLoadStatus(`Loading: Fetching Tickets for ${jobType.name}`)
            setLoadValue(lVal)
            const result = await loadTickets(jobType.name, metadata);
            let jobTickets = {
              name: jobType.name,
              tickets: result,
            };
            updateArr.push(jobTickets);
          }
          setLoadValue(100)
          setLoadStatus(`Finishing up...`)
          setTickets(updateArr);
        }
      } catch (e) {
        console.log("Error fully loading APTE", e);
        console.timeEnd("Loaded APTE");
      }
      setFetching(false);
      console.timeEnd("Loaded APTE");
    };
    onLoad();
  }, []);

  async function checkAuth(metadata) {
    if (zafClient) {
      setCustomer({
        subdomain: metadata.settings.subdomain,
        token: metadata.settings.token,
      });
      return API.post(
        "api",
        `/auth?subdomain=${metadata.settings.subdomain}&token=${metadata.settings.token}`
      );
    } else {
      return false;
    }
  }

  function loadJobs(metadata) {
    return API.get("api", `/jobs?subdomain=${metadata.settings.subdomain}`);
  }

  function loadTypes(metadata) {
    return API.get("api", `/auth?subdomain=${metadata.settings.subdomain}`);
  }

  function loadTickets(jobType, metadata) {
    return API.get(
      "api",
      `/tickets?tags=awaiting_export_${jobType}&customer=${metadata.settings.subdomain}`
    );
  }

  return (
    <EuiProvider colorMode="light">
      <EuiHeader position="fixed" style={{ boxSizing: "border-box" }}>
        <EuiHeaderSectionItem>
          <Link to="/">
            <EuiIcon
              type="https://upload.wikimedia.org/wikipedia/commons/9/9a/GrandCentral_Railway_logo.svg"
              style={{ width: 100, height: 40 }}
            />
          </Link>
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <Link to="/jobs">
            <EuiHeaderLink>Job List</EuiHeaderLink>
          </Link>
        </EuiHeaderSectionItem>
      </EuiHeader>
      {/* We want to load the routes (and proceed to "Home.js aka /") when all content is confirmed */}
      {isFetching ? <LoadingOverlay visible={true} loader={
        <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false} style={{width: 600}}>
            <EuiProgress
            label={<><span style={{fontWeight: 600, fontSize: 14}}>{loadStatus}</span></>}
            valueText={<span style={{fontWeight: 600, fontSize: 14}}>{loadValue}%</span>}
            value={loadValue}
            max={100}
            color="success"
            size="m"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      } /> : <Routes />}
    </EuiProvider>
  );
  } catch (error) {
    return (
      <EuiProvider colorMode="light">
      <EuiHeader position="fixed" style={{ boxSizing: "border-box" }}>
        <EuiHeaderSectionItem>
          <Link to="/">
            <EuiIcon
              type="https://upload.wikimedia.org/wikipedia/commons/9/9a/GrandCentral_Railway_logo.svg"
              style={{ width: 100, height: 40 }}
            />
          </Link>
        </EuiHeaderSectionItem>
      </EuiHeader>
      <EuiCallOut title="Sorry, there was an error" color="danger" iconType="alert">
        <p>{error}</p>
      </EuiCallOut>
    </EuiProvider>
    )
  }
}

export default App;
