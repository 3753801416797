import { atom, selector } from "recoil";

export const fetching = atom({
  key: "fetching",
  default: false,
});

export const activeCustomer = atom({
  key: "customer",
  default: null
})

export const isAuthorised = atom({
  key: "isAuthorised",
  default: true
})

export const jobsList = atom({
  key: "jobsList",
  default: [],
});

export const typesList = atom({
  key: "typesList",
  default: [],
});

export const ticketsList = atom({
  key: "ticketsList",
  default: [],
});

export const jobsCount = selector({
  key: "jobsCount",
  get: ({ get }) => {
    const count = get(jobsList).length;

    return count;
  },
});

export const failedJobsCount = selector({
  key: "failedJobsCount",
  get: ({ get }) => {
    const jobs = get(jobsList);
    let failedJobs = [];
    for (const job of jobs) {
      if (job.status === "Failed") {
        failedJobs.push(job);
      }
    }

    return failedJobs.length;
  },
});

export const completedJobsCount = selector({
  key: "completedJobsCount",
  get: ({ get }) => {
    const jobs = get(jobsList);
    let completedJobs = [];
    for (const job of jobs) {
      if (job.status === "Complete") {
        completedJobs.push(job);
      }
    }

    return completedJobs.length;
  },
});

export const revertedJobsCount = selector({
  key: "revertedJobsCount",
  get: ({ get }) => {
    const jobs = get(jobsList);
    let revertedJobs = [];
    for (const job of jobs) {
      if (job.status === "reverted") {
        revertedJobs.push(job);
      }
    }

    return revertedJobs.length;
  },
});

export const bacsTicketsList = atom({
  key: "bacsTicketsList",
  default: [],
});

export const bacsTicketCount = selector({
  key: "bacsTicketCount",
  get: ({ get }) => {
    const count = get(bacsTicketsList).length;

    return count;
  },
});

export const paypalTicketsList = atom({
  key: "paypalTicketsList",
  default: [],
});

export const paypalTicketCount = selector({
  key: "paypalTicketCount",
  get: ({ get }) => {
    const count = get(paypalTicketsList).length;

    return count;
  },
});
