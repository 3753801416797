import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthorised } from "../store/index";

export default function AuthenticatedRoute({ children, ...rest }) {
  const authenticated = useRecoilValue(isAuthorised);
  return (
    <Route {...rest}>
      {authenticated ? children : <Redirect to="/forbidden" />}
    </Route>
  );
}
