import React from "react";
import {Container} from "@mantine/core";
import { EuiEmptyPrompt } from "@elastic/eui";

export default function NotFound() {
  return (
    <Container
      style={{ height: "100vh", position: "relative", paddingTop: 70 }}
    >
      <EuiEmptyPrompt
        style={{ display: "flex" }}
        iconType="alert"
        title={<h2>Access Denied</h2>}
      />
    </Container>
  );
}
